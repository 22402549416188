import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path1/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from 'docz';
import { Markdown } from '.';
import { Box } from '../Box';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "markdown"
    }}>{`Markdown`}</h1>
    <p>{`The Markdown Component is used to render markdown content. It uses the components defined in HDS, as far as possible.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Markdown} mdxType="Props" />
Props table might not render due to a [bug in docz](https://github.com/pedronauck/docz/issues/777)
    <p>{`Just has one prop. Children which should be a MD string.`}</p>
    <p>{`Because this in MD inside JSX inside MD, the demo is more complex than it's actual use. :)`}</p>
    <p>{`To demo, type some MD below, or copy this bit in:`}</p>
    <pre><code parentName="pre" {...{}}>{`# Headings

Some lorem ipsum paragraphs, ...

### Smaller heading

#### all of them, actually

You can also [link](/components/Link) things.

![Add Images with standard MD](http://placekitten.com/200/300)
![!Add Images with a caption, by prefixing the alt text with a !](http://placekitten.com/200/300)

- Lists are fun
- All the bullets
- 🎯

1. Give
1. Me
1. Numbers 💯

`}</code></pre>
    <Playground __position={1} __code={'<Markdown>{`**Type here...** between the backticks if you\'re using inline MD`}</Markdown>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Markdown,
      Box,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Markdown mdxType="Markdown">{`**Type here...** between the backticks if you're using inline MD`}</Markdown>
    </Playground>
    <p>{`You can also pass children as a string:`}</p>
    <Playground __position={2} __code={'() => {\n  const mdstring = `This is a _great_ way to render content consistently with Hoppin Design.\\n\\n **Especially** if fetched from a DB.`\n  return (\n    <div>\n      <Markdown>{mdstring}</Markdown>\n      or like this:\n      <Markdown children={mdstring} />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Markdown,
      Box,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const mdstring = `This is a _great_ way to render content consistently with Hoppin Design.\n\n **Especially** if fetched from a DB.`;
        return <div>
        <Markdown mdxType="Markdown">{mdstring}</Markdown>
        or like this:
        <Markdown children={mdstring} mdxType="Markdown" />
      </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "images"
    }}>{`Images`}</h2>
    <p>{`To allow MD images to have a caption, not only an alt text, we expanded valid markdown slightly.
The same way an `}<inlineCode parentName="p">{`!`}</inlineCode>{` turns a link `}<inlineCode parentName="p">{`[label](url)`}</inlineCode>{` into an image`}<inlineCode parentName="p">{`![alt](src)`}</inlineCode>{`, you can turn the standard alt text `}<inlineCode parentName="p">{`![alt](src)`}</inlineCode>{` into a caption `}<inlineCode parentName="p">{`![!caption](src)`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`![Add Images with standard MD](http://placekitten.com/200/200)
![!Add Images with a caption, by prefixing the alt text with a "!"](http://placekitten.com/200/202)
`}</code></pre>
    <Playground __position={3} __code={'() => {\n  const altImage = `![Add Images with standard MD](http://placekitten.com/200/200)`\n  const captionImage = `![!Add Images with a caption, by prefixing the alt text with a \"!\"](http://placekitten.com/200/202)`\n  return (\n    <Box textAlign=\"center\">\n      <Markdown>{`${altImage}\n    ${captionImage}\n    `}</Markdown>\n    </Box>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Markdown,
      Box,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const altImage = `![Add Images with standard MD](http://placekitten.com/200/200)`;
        const captionImage = `![!Add Images with a caption, by prefixing the alt text with a "!"](http://placekitten.com/200/202)`;
        return <Box textAlign="center" mdxType="Box">
        <Markdown mdxType="Markdown">{`${altImage}
      ${captionImage}
      `}</Markdown>
      </Box>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      